import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import { MDXRenderer } from "gatsby-plugin-mdx"

const BlogPost = ({ data }) => {
  if (!data) return <></>
  return (
    <Layout>
      <SEO title={data.mdx.frontmatter.title} />
      <section className="blog-post">
        <div className="blog-post-content">
          <div className="main-message">
            <h2>{data.mdx.frontmatter.title}</h2>
            <p>{data.mdx.frontmatter.date}</p>
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
      }
      body
    }
  }
`

export default BlogPost
